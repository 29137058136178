/*  Hide the arrows of input type number */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.AdminLeftSidePanel .navIcons ul {
    position: fixed;
    z-index: 1;
    top: 3%;
    bottom: auto;
    left: -0.8%;
    margin: auto;
    height: 700px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20%;
    padding-bottom: 9%;
}

.adminDashboard .quickStats p {
    text-align: center !important;
}

.adminDashboard .quickStats {
    height: 146px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 12px;
    width: 100%;
    padding: 12% 0% 0% 0%;
}

.adminDashboard .actionDiv {
    position: relative;
    border-bottom: solid 4px #87C748;
    left: 0%;
    width: 84px;
    height: 4px;
}

.adminDashboard .AdminDashboardNavDiv {
    height: 530px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.adminDashboard .AdminDashboardNavDiv .sectionBox {
    width: 214px;
    margin-top: 48px;
    padding: 27px 0px 27px 14px;
    border: 3px solid transparent;
    cursor: pointer;
    height: 101px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 20px;
    margin-left: 40px;
}

.adminDashboard .AdminDashboardNavDiv .activeBox, .adminDashboard .AdminDashboardNavDiv .sectionBox:hover {
    border: 3px solid #87C748 !important;
    cursor: pointer;
}

.adminDashboard .AdminDashboardNavDiv .sectionBox .settingType {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 7px !important;
}

.adminDashboard .AdminDashboardNavDiv .sectionBox .settingName {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.adminDashboard .searchDiv {
    width: 95%;
    height: 59px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border-radius: 100px;
    padding-top: 9px;
}

.adminDashboard .hrDiv {
    margin-top: 10px;
}

.adminDashboard .notificationsDiv {
    height: 530px;
    overflow-y: auto;
    overflow-x: hidden;
}

.adminDashboard .clearText {
    height: 19px;
    text-align: left;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: #87C748;
    cursor: pointer;
}

.adminDashboard .notificationBox {
    display: inline-block !important;
}

.adminDashboard .notificationText {
    height: 34px;
    text-align: left;
    font: normal normal bold 29px/18px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    padding-left: 25px;
    position: relative;
    top: 3px;
}

.adminDashboard .userlistSublabel {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 6px;
}

.adminDashboard #inlineCheckbox2 {
    margin-left: 15px;
}

.adminDashboard .versionsDiv {
    margin-top: 45px;
    min-height: 550px;
    overflow-x: hidden;
    overflow-y: auto;
}

.adminDashboard .softwareDetails .softwareType {
    float: left;
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.adminDashboard .softwareDetails .versionNo {
    float: right;
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #BBC5D5;
}

.adminDashboard .softwareDetails {
    border-bottom: 1px solid lightgray;
}

.adminDashboard .softwareDetails label {
    padding-top: 10px;
}

.adminDashboard .uploadNewVersion {
    width: 258px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    position: relative;
    left: 18%;
    cursor: pointer;
}

.adminDashboard .uploadNewVersion label {
    position: relative;
    cursor: pointer;
    top: 8px;
}

.adminDashboard .uploadNewVersionImg {
    width: 26px;
    cursor: pointer;
    height: 26px;
}

.adminDashboard .uploadNewVersionText {
    height: 22px;
    text-align: left;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    cursor: pointer;
    padding-left: 10px;
}

.adminsoftwareRelease .footerDivHR {
    width: 127%;
    border-top: 1px solid #707070;
    position: relative;
    right: 50px;
}

.addNewVersionDiv {
    padding-left: 54px;
    padding-right: 58px;
    margin-top: 116px;
}

.addNewVersionDiv .backButton {
    margin-bottom: 35px;
    cursor: pointer;
}

.addNewVersionDiv .backButton img, .userRegistration .backButton img, .companyDetailsAdmin .backButton img, .companysSoftwareReleases .backButton img {
    width: 8px;
    cursor: pointer;
    height: 13px;
}

.addNewVersionDiv .backButton span, .userRegistration .backButton span, .companyDetailsAdmin .backButton span, .companysSoftwareReleases .backButton span {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    cursor: pointer;
}

.addNewVersionDiv .uploadNewVersionDiv .uploadNewVersionText {
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.addNewVersionDiv .uploadNewVersionDiv .uploadNewVersionSubText {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.addNewVersionDiv .uploadnewVersionForm {
    margin-top: 45px;
}

.addNewVersionDiv .uploadnewVersionForm input, .addNewVersionDiv .uploadnewVersionForm select {
    width: 100%;
    border: none;
    border-bottom: 2px solid #E9E9F0;
    margin-bottom: 33px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    outline: none;
}

.addNewVersionDiv .uploadnewVersionForm .uploadnewRevBtn {
    margin-top: 50px;
    width: 229px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.adminUserManagement .AddUserAdmin {
    width: 157px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
}

.adminUserManagement .addUserAdmin {
    padding: 7px;
    padding-left: 15px;
}

.adminUserManagement .addUserAdmin img {
    width: 26px;
    height: 26px;
}

.adminUserManagement .addUserAdmin .addUserbtn {
    height: 22px;
    text-align: left;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    padding-left: 10px;
}

.adminUserManagement .resellerListDiv {
    margin-bottom: 50px;
}

.adminUserManagement .usersListResellers {
    min-height: 600px;
    height: 600px;
    margin-bottom: 20px;
}

.adminUserManagement .usersListResellers .customerDownImg img {
    width: 45px;
    height: 45px;
}

.adminUserManagement .usersListResellers .individualReatilSeller {
    border-bottom: 1px solid #E2E7EE;
    padding-bottom: 13px;
    padding-top: 20px;
}

.adminUserManagement .usersListResellers .companyDetailsReseller .companyName {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    margin-bottom: 0px;
}

.adminUserManagement .usersListResellers .companyDetailsReseller .usersDetails {
    height: 17px;
    text-align: left;
    font: normal normal normal 14px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.adminUserManagement .usersListResellers .viewBtn {
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #322A7D;
    position: relative;
    top: 10px;
}

.adminUserregistration .userTitle {
    margin-top: 100px !important;
    margin-bottom: 65px !important;
}

.companyDetailsAdmin .companyDetailsSection {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 30px;
}

.companyDetailsAdmin .companyDetailsSection .customerIcon img {
    width: 45px;
    height: 45px;
}

.companyDetailsAdmin .companyDetailsSection .adminCompanyName {
    height: 21px;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    float: left;
}

.companyDetailsAdmin .companyDetailsSection .deactivateBtn {
    height: 16px;
    text-align: left;
    text-decoration: underline;
    font: normal normal 900 13px/16px Lato;
    letter-spacing: 0px;
    color: #FF001B;
    float: right;
}

.companyDetailsAdmin .companyDetailsSection .companyDetailsDiv {
    border: 1px solid #E4E4E4;
    height: 46px;
    padding: 13px;
}

.companyDetailsAdmin .companyOverallInfo {
    margin-bottom: 40px;
}

.companyDetailsAdmin .companyOverallInfo .keydetailsDiv .keyname {
    float: left;
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.companyDetailsAdmin .companyOverallInfo .keydetailsDiv .keyvalue {
    float: right;
    height: 22px;
    text-align: right;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.companyDetailsAdmin .actionItemText {
    height: 15px;
    text-align: left;
    font: normal normal normal 12px/18px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    display: block;
}

.companyDetailsAdmin .userListbtn {
    width: 78px;
    height: 38px;
    background: #BBC5D5 0% 0% no-repeat padding-box;
    border-radius: 6px;
}

.companyDetailsAdmin .issueLicensebtn {
    width: 98px;
    height: 38px;
    background: #BBC5D5 0% 0% no-repeat padding-box;
    border-radius: 6px;
}

.companyDetailsAdmin .releaseSoftwarebtn {
    width: 138px;
    height: 37px;
    background: #BBC5D5 0% 0% no-repeat padding-box;
    border-radius: 6px;
}

.companyDetailsAdmin .releaseSoftwarebtn, .companyDetailsAdmin .issueLicensebtn, .companyDetailsAdmin .userListbtn {
    text-align: center;
    font: normal normal 900 13px/18px Lato;
    letter-spacing: 0px;
    color: #354052;
    margin-right: 10px;
    border: none;
}

.companyDetailsAdmin .footerDivHR {
    margin-top: 43%;
}

.companyUsersListAdmin .userListfooter {
    margin-top: 6% !important;
}

.companyUsersListAdmin .adminUserlistDetails .totalUsersText {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    float: left;
}

.companyUsersListAdmin .adminUserlistDetails .totalUsersValue {
    height: 22px;
    text-align: right;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    float: right;
}

.companyUsersListAdmin .adminUserlist .indvUsersDiv {
    height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
}

.companyUsersListAdmin .adminUserlist .indvUsersDiv .userIcon {
    width: 49px;
    height: 54px;
}

.companyUsersListAdmin .adminUserlist .indvUsers {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #E2E7EE;
}

.companyUsersListAdmin .adminUserlist .indvUsersDiv .username {
    height: 30px;
    text-align: left;
    font: normal normal bold 25px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.companyUsersListAdmin .adminUserlist .indvUsersDiv .userRole {
    height: 15px;
    text-align: left;
    font: normal normal normal 12px/18px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.companyUsersListAdmin .adminUserlist .indvUsersDiv .deactivateUserBtn {
    height: 15px;
    text-align: center;
    text-decoration: underline;
    font: normal normal 900 12px/17px Lato;
    letter-spacing: 0px;
    color: #FF001B;
    border: none;
    background: transparent;
}

.companyUsersListAdmin .adminUserlist {
    height: 530px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.companyUsersListAdmin .searchDiv {
    margin-bottom: 10px !important;
}

.adminUserlist .addUserDetails {
    margin-top: 24px;
    margin-bottom: 35px;
}

.adminUserlist .addUserDetails .userListTitle {
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    float: left;
    position: relative;
    top: 8px;
}

.adminUserlist .addUserDetails .addUserDiv img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
}

.adminUserlist .addUserDetails .addUserDiv {
    height: 49px;
    width: 157px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    float: right;
    padding: 13px 10px 10px 19px;
    text-align: left;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
}

.adminUserDetails .footerDivHR, .adminUserDetails .footerDiv {
    z-index: 2;
}

.companysSoftwareReleases .backButton {
    margin-bottom: 24px !important;
}

.companysSoftwareReleases .userTitle {
    margin-top: 20px !important;
    margin-bottom: 55px !important;
}

.companysSoftwareReleases .companyDetailsSection .customerIcon img {
    width: 45px;
    height: 45px;
}

.companysSoftwareReleases .companyDetailsSection .companyDetailsDiv {
    border: 1px solid #E4E4E4;
    height: 46px;
    padding: 5px 7px 7px 15px;
    margin-bottom: 50px;
}

.companysSoftwareReleases .adminCompanyName {
    height: 21px;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
}

.companysSoftwareReleases .versionSelectionDiv {
    height: 127px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-bottom: 15px;
}

.companysSoftwareReleases .versionSelectionDiv .selectTitle {
    height: 30px;
    text-align: left;
    font: normal normal bold 25px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    position: relative;
    top: 33%;
    padding-left: 14px;
}

.companysSoftwareReleases .versionSelectionDiv .selectSubTitle {
    height: 12px;
    text-align: left;
    font: normal normal normal 9px/12px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    position: relative;
    top: 33%;
    padding-left: 14px;
}

.companysSoftwareReleases .versionSelectionDiv .selectDiv {
    border: none;
    outline: none;
    border-bottom: 2px solid #C3E6A1;
    width: 100%;
    height: 65px;
    text-align: center;
    font: normal normal normal 46px/64px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    background: transparent;
    position: relative;
    top: 17%;
}

.companysSoftwareReleases .versionSelectionDiv input {
    border: none;
    outline: none;
    border-bottom: 2px solid #C3E6A1;
    width: 100%;
    height: 65px;
    text-align: center;
    font: normal normal normal 46px/64px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    background: transparent;
    position: relative;
    top: 17%;
}

.companysSoftwareReleases .versionSelectionDiv .selectDiv option {
    text-align: center;
    font: normal normal normal 16px/20px Source Sans Pro;
}

.companysSoftwareReleases .shareUpdateBtn {
    width: 185px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 44%;
}

.LicenseDashboard .companyDetails .form-check-inline {
    margin-left: 15px;
    position: relative;
    top: 10px;
}

.adminLicenseDashboard .renewBtn, .adminLicenseDashboard .unAssignBtn, .adminLicenseDashboard .suspendBtn, .adminLicenseDashboard .activateBtn {
    border: none;
    background: transparent;
}

.adminLicenseDashboard .renewBtn {
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #87C748;
    text-decoration: underline;
}

.adminLicenseDashboard .unAssignBtn {
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #F35162;
}

.adminLicenseDashboard .suspendBtn, .adminLicenseDashboard .activateBtn {
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #11141A;
}