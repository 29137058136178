/*  Right Slider  */

.rightSidePanel {
    position: relative;
    height: 100vh;
    background-color: white;
    font-size: 20px;
    width: 500px;
    z-index: 2;
    margin: auto;
    height: 100vh;
    max-height: 100vh;
}

.signin {
    height: 100%;
    padding: 40px;
    padding-top: 300px;
}

.signin_form label {
    font-size: 15px;
}

.signin_form .LoginButton {
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    width: 185px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.signin_form input[type=checkbox] {
    margin-right: 20px;
}

.signin_form .row {
    padding-top: 33px;
}

.signin_form .inputfields {
    border: none;
    border-bottom: 1px solid lightgrey;
    font-size: 15px;
    width: 100%;
    outline: none;
    margin-top: 45px;
}

.signin_form .inputfields:hover {
    outline: none;
}

.signin_form .formFooter {
    width: 100%;
    height: 15px;
    text-align: center;
    font: normal normal normal 11px/13px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    font-size: 11px;
    padding-top: 54%;
}

.links label, .links label:hover {
    color: #43425D;
    cursor: pointer;
}

.loginTitle {
    width: 194px;
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    font-size: 37px;
    font-weight: bold;
    text-transform: uppercase;
}

.loginsubTitle {
    width: 300px;
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.recoveryDetails .backButton  img{
    transform: scale(0.25);
}

.recoveryDetails .backButton  span{
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.recoveryDetails .signin_form .row {
    padding-top: 56px;
}

.recoveryDetails .signin_form .inputfields {
    border: none;
    border-bottom: 1px solid lightgrey;
    font-size: 15px;
    width: 100%;
    outline: none;
    margin-top: 0px !important;
}

.recoveryDetails .signin_form .LoginButton {
    width: 229px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.recoveryDetails .signin_form .formFooter, .recoverySucess .signin_form .formFooter {
    width: 100%;
    height: 15px;
    text-align: center;
    font: normal normal normal 11px/13px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    font-size: 11px;
    padding-top: 95%;
    /* position: relative; */
    bottom: 0;
}

.recoveryDetails .signin, .recoverySucess {
    height: 100%;
    padding: 40px;
    padding-top: 400px;
}

.recoverySucess .loginTitle {
    width: 100%;
    height: 62px;
    text-align: center;
    font: normal normal bold 53px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: uppercase;
}

.recoverySucess .loginsubTitle {
    width: 100%;
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.recoverySucess .signin_form .LoginButton {
    width: 185px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.recoverySucess .signin_form .formFooter {
    width: 100%;
    height: 15px;
    text-align: center;
    font: normal normal normal 11px/13px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    font-size: 11px;
    padding-top: 95%;
}

/*Side Panel Slide Animation*/

.wrapper {
    position: fixed;
    overflow: hidden;
    width: 500px;
    height: 100vh;
    z-index: 10;
    right: 0;
}

.slide {
    position: absolute;
    right: -500px;
    width: 100%;
    height: 100%;
    -webkit-animation: slide 1s forwards;
    -webkit-animation-delay: 0s;
    animation: slide 1s forwards;
    animation-delay: 0s;
    top: 0;
    border-left: 1px solid lightgrey;
}

@-webkit-keyframes slide {
    100% {
        right: 0;
    }
}

@keyframes slide {
    100% {
        right: 0;
    }
}

/*  End Right Slider  */

.deviceList .signin {
    height: 100%;
    padding: 40px;
    padding-top: 27px;
}

.deviceList .searchDiv {
    background-color: #F7F8FA;
    margin-bottom: 50px;
}

.deviceList .deviceDisplayText:first-child {
    height: 55px;
    text-align: left;
    font: normal normal normal 18px/28px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.deviceList .individualDevice img {
    width: 64px;
    height: 64px;
}

.deviceList .individualDevice .deviceName {
    height: 21px;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
}

.deviceList .individualDevice .deviceStatus {
    height: 15px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.deviceList .individualDevice .closeBtn {
    height: 28px;
    text-align: left;
    font: normal normal normal 23px/28px Lato;
    letter-spacing: 0px;
    color: #F35162;
    cursor: pointer;
}

.deviceList .individualDevice:nth-child(1) {
    margin-top: 27px !important;
}

.deviceList .individualDevice:not(:first-child) {
    margin-top: 20px;
}

.deviceList .deviceDetails {
    padding-top: 2% !important;
}

.deviceList .searchDiv {
    width: 100%;
    height: 59px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border-radius: 100px;
    padding-top: 9px;
}

.deviceList .searchDiv i {
    padding: 12px;
    font-size: 16px;
}

.deviceList .searchDiv span {
    font-size: 22px;
    color: #BBC5D5;
    padding: 6px;
}

.deviceList .searchDiv input {
    background: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    height: 35px;
}

.deviceList .devicesContainer {
    max-height: 690px;
    height: 690px;
    overflow-y: auto;
    overflow-x: hidden;
}

.deviceList .devicesContainer::-webkit-scrollbar {
    width: 5px;
}

/* Track */

.deviceList .devicesContainer::-webkit-scrollbar-track {
    background: #F2F2F2 0% 0% no-repeat padding-box;
}

/* Handle */

.deviceList .devicesContainer::-webkit-scrollbar-thumb {
    background: #BBC5D5 0% 0% no-repeat padding-box;
}

/* Handle on hover */

.deviceList .devicesContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.resetPassword {
    padding-top: 350px;
}

.resetPassword .loginTitle {
    height: 44px;
    text-align: left;
    font: normal normal bold 31px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 344px;
}

.resetPassword .loginsubTitle {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 57px;
}

.resetPassword .confirmPassword, .resetPassword .newPassword {
    border: none;
    border-bottom: 1px solid lightgrey;
    font-size: 15px;
    width: 100%;
    outline: none;
    height: 21px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    padding-bottom: 10px;
}

.resetPassword .newPassword {
    margin-bottom: 46px;
}

.resetPassword .confirmPassword {
    margin-bottom: 86px;
}

.resetPassword .submitNewpassword {
    width: 229px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}