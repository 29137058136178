.fileManager .fileDetails .folderIcon {
    width: 40px;
    height: 33px;
    margin-bottom: 20px;
}

.fileManager .fileDetails .addFolderIcon {
    width: 70px;
    height: 33px;
    margin-bottom: 20px;
}

.fileManager .fileDetails .folderName {
    position: relative;
    text-align: center;
    background: transparent;
    border-radius: 0px;
    cursor: pointer;
    height: 30px !important;
    border: none !important;
    outline: none;
}

.fileManager .filesList {
    padding-left: 41px;
    padding-right: 41px;
}

.fileManager .filesList .row {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
}

.fileManager .filesList .fileslistNavBar {
    padding-top: 30px;
    padding-right: 25px;
    margin-bottom: 40px;
}

.fileManager .filesList .uploadNewFiles {
    width: 200px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272d3b33;
    border-radius: 10px;
    position: relative;
    float: right;
    margin-right: 20px;
    cursor: pointer;
}

.fileManager .filesList .uploadNewFiles img {
    width: 26px;
    height: 26px;
    position: relative;
    top: 12px;
    left: 20px;
}

.fileManager .filesList .uploadNewFiles button {
    height: 22px;
    text-align: left;
    font: normal normal bold 15px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    background: none;
    outline: none;
    border: none;
    position: relative;
    top: 14px;
    left: 25px;
}

.fileManager .expInfo .statusDetails span {
    color: #87C748 !important;
}

.fileManager .filesList .backbutton {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    position: relative;
    cursor: pointer;
    float: left;
    top: 15%;
}

.fileManager .filesList .backbutton span {
    padding-right: 15px;
}

.fileManager .filesList .backbutton img {
    width: 8px;
    height: 13px;
}

.fileManager .filesList .rdt_TableHeader {
    display: none !important;
}

.fileManager .filesList .filesListTable .rdt_Table {
    max-height: 500px;
    height: 500px;
    overflow: auto;
}

.fileManager .filesList .filesListTable .rdt_Table .view {
    /* width: 150px; */
    background-color: #B7B8BA;
    text-align: left;
    font: normal normal normal 14px/19px Lato;
    letter-spacing: 0px;
    padding: 3px 16px 3px 16px;
    color: #354052;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-right: 20px;
}
.fileManager .filesList .filesListTable .rdt_Table .osIcon img {
    width: 35px;
    margin-right: 10px;
    cursor: pointer;
}
.fileManager .filesList .filesListTable .rdt_Table .download {
    /* width: 150px; */
    background-color: #322A7D;
    text-align: left;
    font: normal normal normal 14px/19px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 3px 16px 3px 16px;
    border-radius: 3px;
}

.fileManager .whiteBacground {
    background-color: #FFFFFF;
    max-height: 69vh;
    height: 69vh;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 10px;
    padding-top: 40px;
}

.fileManager .fileDetails {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
    height: 115px;
    padding: 2%;
}

.fileManager .fileDetails input {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
    height: 115px;
    padding: 2%;
}

.fileManager .fileDetails:hover {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    cursor: pointer;
    height: 115px;
    padding: 2%;
}

.fileManager .searchDiv {
    width: 426px;
    height: 59px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 100px;
    float: right;
    padding-left: 20px;
    padding-top: 2px;
}

.fileManager .companyDetails span:first-child {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.fileManager .companyDetails span:first-child select option {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.fileManager .companyDetails span:first-child select {
    width: 435px;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 15px;
    outline: none;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.fileManager .companyDetails span:nth-child(2) img {
    position: relative;
    width: 26px;
    height: 26px;
    top: 10px;
    left: 30px;
}

.fileManager .searchDiv i {
    width: 24px;
    height: 24px;
}

.fileManager .searchDiv span {
    font-size: 30px;
    color: lightgray;
}

.fileManager .searchDiv input {
    width: 85%;
    border: none;
    outline: none;
    background: transparent;
    padding-left: 18px;
}