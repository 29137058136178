.forumDashboard .contentPanel, .forumDashboard .leftPanel, .forumDashboard .rightPanel {
    min-height: 100vh;
    overflow: hidden;
    height: 100vh;
}

.forumDashboard .contentPanel {
    background-color: #F7F8FA;
}

.forumDashboard .searchBar {
    width: 100%;
    height: 60px;
    background-color: #E8E8E8;
    border-radius: 41px;
    padding-left: 29px;
    font-size: 21px;
    position: relative;
    border: none;
    outline: none;
    margin-bottom: 20px;
}

.forumDashboard .leftPanel {
    padding-right: 0px;
}

.forumDashboard .title {
    height: 44px;
    width: 100%;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: none;
    margin-top: 80px;
    margin-bottom: 80px;
}

.forumDashboard .navLinks {
    padding-left: 15%;
}

.forumDashboard .navLinks a:hover {
    text-decoration: none;
}

.forumDashboard .navLinks button {
    display: block;
    margin: 31px 0px;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    height: 45px;
    text-align: left;
    font: normal normal bold 21px/23px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 100%;
}

.forumDashboard .allposts, .forumDashboard .myPosts {
    padding-left: 25px;
    padding-right: 25px;
}

.forumDashboard .navLinks .active {
    border-right: 3px solid #87C748 !important;
    cursor: pointer;
}

.forumDashboard .AllpostsTitle {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
    font: normal normal bold 26px/23px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 100%;
}

.forumDashboard .cDmETx {
    display: none !important;
}

.forumDashboard .ecDzaP * {
    background: transparent !important;
    background-color: transparent;
    font: normal normal bold 16px/16px Raleway;
    line-height: 20px;
}

.forumDashboard .viewButton {
    border: none;
    outline: none;
    border-bottom: 2px solid lightgray;
    font: normal normal bold 18px/21px Raleway;
    color: lightgray;
}

.forumDashboard .cchvzS {
    padding: 20px 0px;
    font: normal normal bold 16px/16px Raleway;
}

.forumDashboard .fhaVWy {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0;
    height: 720px;
    overflow-x: hidden;
    overflow-y: auto;
}

.forumDashboard .addnewPost {
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    padding: 6px 52px 7px 52px;
    border: none;
    outline: none;
    font: normal normal bold 16px/16px Raleway;
}

.forumDashboard .postDetailsDiv {
    margin-top: 26%;
    padding-left: 8%;
    padding-right: 5%;
}

.forumDashboard .postDetailsDiv .topic {
    height: 28px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    outline: none;
    font: normal normal 350 24px/30px lato;
}

.forumDashboard .postDetailsDiv .detailsInfo {
    height: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    outline: none;
    font: normal normal bold 14px/16px raleway;
    line-height: 20px;
}

.forumDashboard .commentBoxDiv {
    padding-left: 10%;
    padding-right: 5%;
    height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 15px;
}

.forumDashboard .commentBox {
    background-color: #F5F5F5;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 20px;
    width: 100%;
    font: normal normal bold 12px/16px raleway;
    line-height: 20px;
    padding: 20px 20px 10px 20px;
}

.forumDashboard .newCommnetBox {
    position: absolute;
    bottom: 8%;
    z-index: 5;
    width: 89%;
    left: 5%;
}

.forumDashboard .actionBtns {
    height: 56px;
    width: 80%;
    font: normal normal bold 14px/16px raleway;
}

.forumDashboard .createPost {
    margin-top: 25%;
    padding-left: 10%;
    padding-right: 10%;
}

.forumDashboard .createPost input {
    border: none;
    display: block;
    width: 100%;
    line-height: 30px;
    border-bottom: 1px solid lightgray;
}

.forumDashboard .createPost .form * {
    outline: none !important;
}

.forumDashboard .createPost .form {
    margin-top: 20%;
    width: 100%;
}

.forumDashboard .createPost .form .col-12 {
    margin-bottom: 40px;
}

.forumDashboard .createPost .css-yk16xz-control {
    border: none;
    border-bottom: 1px solid lightgray;
    /* margin: 0%;
    margin-bottom: 20px; */
}

.forumDashboard .createPost .submitButton {
    margin-top: 30px;
    background-color: #43425D;
    color: #fff;
    width: 170px;
    height: 53px;
    font: normal normal bold 18px/22px raleway;
}

.forumDashboard .css-1okebmr-indicatorSeparator {
    display: none !important;
}

.forumDashboard .userlistlabel {
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.forumDashboard .userlistSublabel {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.forumDashboard .css-1pahdxg-control:hover {
    box-shadow: transparent !important;
    border-color: transparent !important;
}

.forumDashboard .setpreferredSol {
    color: green;
    font-size: 20px;
    padding-left: 20px;
    float: right;
    cursor: pointer;
}

.forumDashboard .deletePost {
    color: red;
    font-size: 20px;
    float: right;
    cursor: pointer;
}

.forumDashboard .prefSolution {
    padding-left: 10%;
}

.forumDashboard .preferredSol span{
    position: relative;
    right: 18px;
    top: 1px;
}

.forumDashboard .preferredSol {
    background-color: #C3E6A1;
    transform: rotate( -90deg);
    width: 37%;
    font-size: 10px;
    left: -15%;
    text-align: center;
    position: absolute;
    z-index: 9;
}

.forumDashboard .commentBox {
    padding-left: 5%;
}