.DeviceDashboard .activityLogDiv .licenseCount span {
    height: 27px;
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    margin-right: 45px;
}

.DeviceDashboard .actionDiv {
    position: relative;
    border-bottom: solid 4px #FFA101;
    left: 1.5%;
    width: 84px;
    height: 4px;
}

.DeviceDashboard .devicesRow {
    margin-top: 42px;
}

.DeviceDashboard .devicesDiv {
    max-height: 670px;
    height: 670px;
    overflow-y: hidden;
    overflow-x: hidden;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

.DeviceDashboard .rdt_TableHeader {
    display: none;
}

.DeviceDashboard .osIcon img {
    width: 64px;
    height: 64px;
}

.DeviceDashboard .ezEBKV {
    margin-top: 30px;
    border: none;
}

.DeviceDashboard .dSYVyP {
    height: 18px;
    text-align: center !important;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.DeviceDashboard .cchvzS {
    border: none !important;
    padding-bottom: 25px;
}

.DeviceDashboard .iAFSOj {
    min-height: 585px;
    overflow-y: auto;
    overflow-x: hidden;
}

.DeviceDashboard .viewButton {
    width: 150px;
    height: 43px;
    background: #B7B8BA 0% 0% no-repeat padding-box;
    border-radius: 6px;
    font: normal normal 900 13px/12px Lato;
    letter-spacing: 0px;
    color: #11141A;
    border: none;
}

.DeviceDashboard .devicesSlNo {
    width: 100%;
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.DeviceDashboard .devicesName {
    width: 100%;
    height: 46px;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    padding: 11px 5px 8px 20px;
}

.DeviceDashboard .devicesStatus {
    width: 100%;
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    opacity: 1;
}

.DeviceDashboard .devicesExpName {
    width: 100%;
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.DeviceDashboard .devicesTagName {
    width: 80%;
    height: 24px;
    padding-top: 4px;
    background: #322A7D 0% 0% no-repeat padding-box;
    border-radius: 9px;
    text-align: center;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.deviceDetails {
    padding-top: 110px !important;
    height: 100%;
    /* padding: 40px; */
}

.deviceDetails .loginTitle {
    height: 44px;
    width: 100%;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: none;
}

.deviceDetails .addExpSubTitle {
    height: 20px;
    width: 100%;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.deviceDetails .licenseKeyDetails {
    width: 100%;
}

.deviceDetails .licenseKeyDetails .keyIcon img {
    width: 64px;
    height: 64px;
}

.deviceDetails .licenseKeyDetails .keyDetails {
    height: 46px;
    width: 315px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070 !important;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    padding: 13px;
    cursor: pointer;
}

.deviceDetails .licenseKeyDetails .keyDetails .key {
    display: inline-block;
    width: 70%;
}

.deviceDetails .licenseKeyDetails .keyDetails .key .deviceName {
    height: 21px;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    float: left;
    padding-top: 5px;
}

.deviceDetails .licenseKeyDetails .keyDetails .key .deleteBTN {
    height: 19px;
    text-align: center;
    text-decoration: underline;
    font: normal normal 900 16px/22px Lato;
    letter-spacing: 1.92px;
    color: #FF001B;
    float: right;
    padding-top: 5px;
}

.deviceDetails .keyInfo {
    margin-top: 40px;
}

.deviceDetails .keydetailsDiv .keyname {
    float: left;
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.deviceDetails .keydetailsDiv .keyvalue {
    float: right;
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.deviceDetails .keydetailsDiv .tagValue {
    height: 24px;
    background: #322A7D 0% 0% no-repeat padding-box;
    border-radius: 9px;
    text-align: left;
    font: normal normal normal 18px/14px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 4px 25px 4px 25px;
}

.deviceDetails .assignNewDetails {
    height: 270px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 77px;
}

.deviceDetails .assignNewDetails select {
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 2px solid #E9E9F0;
    height: 21px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    outline: none;
    margin-top: 40px;
    margin-bottom: 23px;
}

/* .deviceDetails .selectTagContainer {
    padding-top: 13%;
} */

.deviceDetails .changetag {
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 2px solid #E9E9F0;
    height: 35px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    outline: none;
    margin-bottom: 15px;
}

.deviceDetails .css-yk16xz-control, .deviceDetails .css-yk16xz-control:hover {
    border: none;
    background: transparent;
}

.deviceDetails .css-1okebmr-indicatorSeparator {
    display: none;
}

.deviceDetails .saveChanges {
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 25px;
}

.DeviceDashboard .devicesDiv .rdt_TableRow, .DeviceDashboard .devicesDiv .rdt_TableHead {
    border-bottom: none!important;
    padding: 20px 0px;
}

.DeviceDashboard .devicesDiv .eYvtlD {
    border-bottom: none;
}

.DeviceDashboard .devicesDiv .hmjaIB {
    padding: 10px 0px 10px 0px;
}

.DeviceDashboard .devicesDiv .kvHQHO {
    height: 610px;
    max-height: 610px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 20px;
}

.DeviceDashboard .companyDetails span:first-child {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.DeviceDashboard .companyDetails span:first-child select option {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.DeviceDashboard .companyDetails span:first-child select {
    width: 347px;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 15px;
    outline: none;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.DeviceDashboard .companyDetails span:nth-child(2) {
    width: 258px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    float: right;
    cursor: pointer;
}

.DeviceDashboard .companyDetails span:nth-child(2) img {
    position: relative;
    width: 26px;
    height: 26px;
    top: 10px;
    left: 30px;
}